<template>
  <div>
    <!-- Table -->
    <b-row>
      <b-col cols="12">
        <div class="table-container">
          <b-table
            id="shop-order-payments-table"
            style="width: 100%; table-layout: fixed;"
            class="table-striped"
            :fields="fields"
            :items="items"
            head-variant="light"
            ref="shop-order-payments-table"
            >

            <template #cell(empty)="data">

              <div class="d-flex justify-content-end">
                <!--<a class="btn btn-icon btn-light btn-sm mx-2"
                  @click.prevent="delete_shop_order_payment_clicked(data.item)">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                  </span>
                </a>-->
              </div>
            </template>

          </b-table>

          <p v-if="items.length === 0">{{ $t('SHOP_ORDER.NONE_FOUND') }}</p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';

export default {

  name: 'MemberShopOrderPaymentsTable',

  props: ['member', 'shop_order'],
  emits: ['selected', 'delete'],

  mixins: [toasts],

  components: {

  },

  watch: {
    shop_order: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        if (this.shop_order) {
          this.load_shop_order_payments(this.shop_order.shop_order_id);
        }
      }
    },
  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {

    edit_shop_order_payment_clicked(shop_order) {
      this.$nextTick(() => {
        this.$emit('selected', shop_order);
      });
    },

    delete_shop_order_payment_clicked(shop_order) {
      this.$nextTick(() => {
        this.$emit('delete', shop_order);
      });
    },

    async load_shop_order_payments(shop_order_id) {
      try {
        const res = await axios.get(`/shop_order/${shop_order_id}/payments`);

        if (res.status === 200) {
          this.items = res.data;
        }
      }
      catch (err) {
        console.error('load_shop_order_payments error', err)
      }
    },


  },

  async mounted() {

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }

    if (this.shop_order) {
      await this.load_shop_order_payments(this.shop_order.shop_order_id);
    }

  },

  data() {
    return {

      items: [],

      fields: [

        {
          key: 'created_at',
          label: this.$t('SHOP_ORDER.PAID_AT'),
          sortable: false,
          mobile: false,
        },
        {
          key: 'amount',
          label: this.$t('SHOP_ORDER.PAYMENT_AMOUNT'),
          sortable: false,
          mobile: true
        },
        {
          key: 'payment_method',
          label: this.$t('SHOP_ORDER.PAYMENT_METHOD'),
          sortable: false,
          mobile: true,
          formatter: (_, __, item) => {
            return item.payment_method.toUpperCase()
          }
        },
        {
          key: 'empty',
          label: '',
          thClass: ''
        }
      ],

      show_form: true,

    };
  }
};

</script>
<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
