<!--

  View for member attributes.

  No time to refactor this to the new Form and Table components.

-->
<template>
  <div class="container">

    <MemberPropertyAssignEditor 
      :member_id="member.member_id"
      :item="selected_item"
      :property_groups="property_groups"

      ref="member-property-assign-editor"

      @created="created_member_property_assign"
      @updated="updated_member_property_assign"
    />

    <b-row class="mb-4">
      <b-col cols="9"></b-col>
      <b-col cols="3" class="d-flex justify-content-end">
        <a
          class="btn btn-primary font-weight-bolder font-size-sm mr-2"
          style="max-height: 38px;"
          @click.prevent="create_member_property_assign_clicked"
        >
          <i class="menu-icon flaticon2-plus pr-0 pr-md-2" style="font-size: 1em"></i
          ><span class="d-none d-md-inline-block">{{$t('COMMON.ADD')}}</span>
        </a>
      </b-col>
    </b-row>

    <hr class="my-3" />

    <b-table 
      id="attributes-table"
      style="width: 100%; table-layout: fixed;"
      class="table-striped"
      :fields="fields"
      head-variant="light"
      responsive 
      :items="items">
      <template #cell(action)="data">
        <a class="btn btn-icon btn-light btn-sm mx-1" @click.prevent="edit_member_property_assign_clicked(data.item)">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <!--begin::Svg Icon-->
            <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            <!--end::Svg Icon-->
          </span>
        </a>

        <a class="btn btn-icon btn-light btn-sm mx-1" @click.prevent="delete_member_property_assign_clicked(data.item.id)">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <!--begin::Svg Icon-->
            <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            <!--end::Svg Icon-->
          </span>
        </a>
      </template>
    </b-table>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { mapGetters } from 'vuex';

import MemberPropertyAssignEditor from '@/view/components/member_editor_v2/sections/MemberPropertyAssignEditor.vue';

export default {

  name: 'MemberPropertyAssignView',

  props: ['member','items','company','creating_member'],

  mixins: [ toasts ],

  components: {
    MemberPropertyAssignEditor
  },

  watch: {
    
  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId']),
  },

  methods: {

    created_member_property_assign(item) {
      this.selected_item = item;

      this.$refs['member-property-assign-editor'].hide();

      this.$emit('created', item);
    },

    updated_member_property_assign(item) {
      this.selected_item = { ...item };
      this.$emit('updated', item);
      this.$refs['member-property-assign-editor'].hide();
    },

    async load_property_groups() {
      
      try {
        const res = await axios.get(`/property_group/extlist?company_id=${this.currentCompanyId}`);

        if (res.status === 200) {
          this.property_groups = res.data;
          this.property_groups = [ ...this.property_groups ];
        }
      }
      catch (err) {
        console.error('load_property_groups error', err);
      }
      
    },

    create_member_property_assign_clicked() {
      this.selected_item = {};

      this.$nextTick(()=>{
        this.$refs['member-property-assign-editor'].show();
      });
      
    },

    edit_member_property_assign_clicked(item) {
      
      this.selected_item = item;

      this.$nextTick(()=>{
        this.$refs['member-property-assign-editor'].show();
      });

    },

    async delete_member_property_assign_clicked(id) {
      const res = await axios.delete(`/member_property_assign/${id}`);

      if (res.status === 204) {
        this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.DELETED'));

        this.$emit('deleted', id);
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_DELETE'));
      }
    },
    
  },

  async mounted() {

    await this.load_property_groups();
    
  },

  data() {
    return {

      property_groups: [],

      selected_item: {},

      fields: [
        { 
          key: 'group', label: this.$t('COMMON.COLUMN'), thStyle: 'width: 20%',
          formatter: (_, __, item) => {
            return item.property.group.name;
          }
        },
        { key: 'created_at', label: this.$t('COMMON.CREATED_AT'), thStyle: 'width: 15%' },
        { key: 'value', label: this.$t('COMMON.VALUE'), thStyle: 'width: 50%',
          formatter: (_, __, item) => {
            if (!item.property) return 'Fel';

            return item.property.is_free_text ? item.text : item.property.name;
          }
        },
        { key: 'action', label: '', thStyle: 'width: auto' }
      ],
      attrItems: [],

      creating: true,

      selected_item: {},

    };
  }
};

</script>

<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
