<!--

  View for shop orders components.

  Old legacy code, just copy pasted it into here. Need or refactor at some point.

-->
<template>
  <div class="container">
    <b-form-textarea class="mt-4" rows="12" id="member-notes" v-model="local_member.notes"/>

    
    <RightSaveButton
      class="mt-8"
      ref="save-button"
      :text="$t('COMMON.SAVE')"
      @clicked="submit_clicked()"
    />

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {

  name: 'MemberNotesView',

  props: ['member','items','company','creating_member'],
  emits: ['update'],
  mixins: [ toasts ],

  components: {
    RightSaveButton
  },

  watch: {
    member: {
      deep: true,
      handler(val) {
        this.local_member = { ...val }
      }
    },
  },

  computed: {
    is_mobile() {
      return is_mobile();
    },


  },

  methods: {
    submit_clicked() {
      this.on_change();
    },
    on_change() {
      this.$emit('update', this.local_member.member_id, this.local_member.notes, this.local_member);

      this.$refs['save-button'].stop();
    }
  },

  mounted() {
    if (this.member) {
      this.local_member = { ...this.member };
    }
    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {
      local_member: {}
    };
  }
};

</script>

<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
