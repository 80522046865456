<!--

  Send invoice email

-->
<template>
  <div>

    <b-modal :title="$t('INVOICE.SEND_EMAIL')" ref="edit-form" hide-footer>

      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">
        <b-row>
          <b-col cols="12">
            <b-form-group label-size="sm" label-cols="4" content-cols="6" :label="$t('TEMPLATE.NAME')">

              <b-form-select v-model="email_template_id" :options="email_template_options"></b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="error">
          <b-col cols="12">
            <p style="color: red;">{{ error }}</p>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <RightModalSaveAndCloseButtons
              :disabled="error"
              :text="$t('COMMON.SEND_EMAIL')"
              :spin="true"
              @clicked="on_submit"
              @close="$refs['edit-form'].hide()" ref="saveButton" />
          </b-col>
        </b-row>
      </b-form>

    </b-modal>


  </div>
</template>


<script>

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import { mapGetters } from 'vuex';
import store from '@/core/services/store';
import axios from 'axios';



export default {

  name: 'MemberInvoiceSendEmailPopup',

  props: ['member', 'invoice_id'],

  mixins: [toasts],

  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {
    currentCompanyId() {

    },
    currentPeriodId() {

    }
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
    is_mobile() {
      return is_mobile();
    },
    period_options() {
      return this.periods.map((item) => ({ text: item.name, value: item.id }));
    },
    company_options() {
      if (!this.companies) {
        return [];
      }

      return this.companies.map((item) => ({ text: item.name, value: item.id }));
    },
    error() {
      if (this.email_template_id) {
        const template = this.templates.find(item => item.template_id === this.email_template_id);

        if (template) {
          if (!template.has_link_str) {
            return this.$t('INVOICE.TEMPLATE_MISSING_INVOICE_PDF_LINK')
          }
        }
      }
      else if (this.email_template_id === null) {
        return this.$t('INVOICE.MUST_SELECT_TEMPLATE')
      }

      return null;
    }
  },

  mounted() {
    this.load_templates();
  },

  methods: {
    show() {
      this.$refs['edit-form'].show();
    },

    async on_submit() {

      try {
        const res = await axios.post(`/invoice/email/${this.invoice_id}`, {
          email_template_id: this.email_template_id
        });

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('INVOICE.EMAIL_SENT'));
          this.$refs['saveButton'].stop();
          this.$refs['edit-form'].hide();

          return;
        }
      }
      catch (err) {
        console.error('on_submit, error sending email');
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('INVOICE.UNABLE_SEND_EMAIL'))

      this.$refs['saveButton'].stop();
      this.$refs['edit-form'].hide();
    },


    async load_templates() {
      try {
        const res = await axios.get(`/template?company_id=${this.currentCompanyId}`);

        if (res.status === 200) {

          const templates = res.data;
          this.templates = res.data.map(item => ({ template_id: item.template_id, has_link_str: item.text.indexOf('%INVOICE_PDF_LINK%') >= 0 }));

          this.email_template_options = [];

          for (const t of templates) {
            if (t.is_sms) {
              //this.email_template_options.push({ value: t.template_id, text: t.name });
            }
            else {
              this.email_template_options.push({ value: t.template_id, text: t.name });
            }
          }

          return;
        }
      }
      catch (err) {
        console.error('load_templates', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));
    },
  },

  data() {
    return {
      email_template_options: [],
      email_template_id: null,
      templates: [],
    };
  }
};

</script>
<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
