<!--

  View for Samfund

-->
<template>
  <div class="container">

    <b-row>
      <b-col md="6">
        <b-form-group :label="$t('SKV.SKV_STATUS')">
          <b-form-select :disabled="creating_member" :options="skv_options" v-on:change="update_skv_status(local_member.skv_status)" v-model="local_member.skv_status"/>
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group :label="$t('SKV.SKV_PERIOD')">
          <b-form-select :disabled="creating_member" :options="period_options" v-on:change="update_skv_period_id(local_member.skv_period_id)" v-model="local_member.skv_period_id"/>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-form-group :label="$t('SKV.COMPANY')">
          <b-form-select
            :disabled="creating_member"
            :options="company_options"
            v-on:change="update_company(company_id)"
            v-model="company_id"/>
        </b-form-group>
      </b-col>

    </b-row>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { mapGetters } from 'vuex';


export default {

  name: 'MemberFaithView',

  props: ['member','member_companies','creating_member'],
  emits: ['updated'],
  mixins: [ toasts ],

  components: {

  },

  watch: {
    member: {
      deep: true,
      handler(val) {
        this.local_member = { ...val }
      }
    },
    member_companies: {
      deep: true,
      handler(val) {
        this.assign_company_id();
      }
    }
  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['periods','companies']),
    period_options() {
      return this.periods.map(( item ) => ( { text: item.name, value: item.id }));
    },
    company_options() {
      return this.companies.map(( item ) => ( { text: item.name, value: item.company_id }));
    }
  },

  methods: {

    assign_company_id() {

      if (!this.member_companies || this.member_companies.length === 0) {
        console.error('cant assign company_id');
        return;
      }

      this.member_company = this.member_companies[0];
      this.company_id = this.member_companies[0].company_id;
    },

    async update_company(company_id) {
      try {
        const res = await axios.put(`/member_company/${this.member_company.member_company_id}`, { company_id: company_id });

        if (res.status === 200) {
          this.toastr('danger', this.$t('COMMON.OK'), this.$t('SKV.MEMBER_COMPANY_UPDATED'));
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SKV.UNABLE_UPDATE_MEMBER_COMPANY'));
        }
      }
      catch (err) {
        console.error('update_company', err);

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SKV.UNABLE_UPDATE_MEMBER_COMPANY'));
      }
    },

    async update_skv_period_id(skv_period_id) {

      try {
        const res = await axios.put(`/member/${this.local_member.member_id}`, { skv_period_id });

        if (res.status !== 200) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SKV.UNABLE_UPDATE'));
        }

        this.local_member.skv_period_id = res.data.skv_period_id;

        this.$emit('updated');

        this.toastr('success', this.$t('COMMON.OK'), this.$t('SKV.UPDATED'));
      }
      catch (err) {
        console.error(err);
      }
    },

    async update_skv_status(skv_status) {

      try {
        const res = await axios.put(`/member/${this.local_member.member_id}`, { skv_status });

        if (res.status !== 200) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SKV.UNABLE_UPDATE'));
        }

        this.local_member.skv_status = res.data.skv_status;

        this.$emit('updated');

        this.toastr('success', this.$t('COMMON.OK'), this.$t('SKV.UPDATED'));
      }
      catch (err) {
        console.error(err);
      }
    },

  },

  mounted() {
    if (this.member) {
      this.local_member = {...this.member}
    }
    this.assign_company_id();
  },

  data() {
    return {
      local_member: {},
      member_company: null,
      company_id: null,

      skv_options: [
        { text: this.$t('SKV.SKV_STATUSES.IGNORE'), value: 'IGNORE' },
        { text: this.$t('SKV.SKV_STATUSES.MINOR_IGNORE'), value: 'MINOR_IGNORE' },
        { text: this.$t('SKV.SKV_STATUSES.REGISTERED'), value: 'REGISTERED' },
        { text: this.$t('SKV.SKV_STATUSES.DELETED'), value: 'DELETED' },
        { text: this.$t('SKV.SKV_STATUSES.PEN_REGISTER'), value: 'PEN_REGISTER' },
        { text: this.$t('SKV.SKV_STATUSES.PEN_DELETED'), value: 'PEN_DELETED' },
        { text: this.$t('SKV.SKV_STATUSES.ERR_PERSONNR'), value: 'ERR_PERSONNR' },
        { text: this.$t('SKV.SKV_STATUSES.ERR_DUPLICATE'), value: 'ERR_DUPLICATE' },
      ]

    };
  }
};

</script>

<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
