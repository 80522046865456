<!--

  Controller for Parents

  if person_id is set -> Person
  if member_id is set -> Member

-->
<template>
  <div class="container">

    <Confirm
      :title="$t('PARENT.DELETE')"
      :message="$t('PARENT.CONFIRM_DELETE')"
      ref="confirmModal"
      @cancel="$refs['confirmModal'].hide()"
      @confirm="confirm_delete_parent"
    />

    <b-modal
      :title="creating ? $t('PARENT.CREATING') : $t('PARENT.EDITING')"
      ref="edit-form"
      hide-footer
      >

      <!-- Edit form -->
      <MemberParentForm
        :member="member"
        :item="selected_item"
        @cancel="() => { this.$refs['edit-form'].hide(); }"
        @created="parent_created"
        @updated="parent_updated"
      />


    </b-modal>

    <!-- Table -->
    <MemberParentTable
      :items="items"
      :view_only="false"
      :member="member"
      :company="company"
      @select="select_item_clicked"
      @delete="delete_item_clicked"
      @create="create_item_clicked"
    />

  </div>

</template>


<script>

import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import MemberParentForm from '@/view/components/member_editor_v2/sections/member_parents/MemberParentForm.vue';
import MemberParentTable from '@/view/components/member_editor_v2/sections/member_parents/MemberParentTable.vue';

export default {

  name: 'MemberCompaniesView',

  props: ['member','items','company'],
  emits: ['updated', 'created', 'deleted'],
  mixins: [ toasts ],

  components: {
    MemberParentForm,
    MemberParentTable,
  },

  watch: {

  },

  computed: {
    creating() {
      return !(this.selected_item && (this.selected_item.person_id || this.selected_item.member_id))
    },
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {

    async confirm_delete_parent() {
      try {

        let res = null;

        if (this.item_to_delete.member_id) {
          res = await axios.delete(`/member/${this.item_to_delete.member_id}`);
        }
        else {
          res = await axios.delete(`/person/${this.item_to_delete.person_id}`);
        }

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PARENT.DELETED'));

          this.$emit('deleted', this.item_to_delete);
        }
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PARENT.UNABLE_DELETE'));
      }

    },


    async delete_item_clicked(item) {
      this.item_to_delete = item;
      this.$refs['confirmModal'].show();
    },


    parent_deleted(item) {
      this.$emit('updated', item);

      this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('PARENT.DELETED'));

      this.$refs['edit-form'].hide();
    },

    parent_created(item) {
      this.$emit('created', item);

      this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('PARENT.CREATED'));

      this.$refs['edit-form'].hide();
    },


    create_item_clicked() {
      this.selected_item = {};

      this.$nextTick(()=>{
        this.$refs['edit-form'].show();
      });
    },

    parent_updated(item) {
      this.$emit('updated', item);

      this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('PARENT.UPDATED'));
    },


    async select_item_clicked(item) {
      this.selected_item = item;

      this.$nextTick(()=>{
        this.$refs['edit-form'].show();
      });
    },

  },

  mounted() {


  },

  data() {
    return {

      selected_item: {},

      item_to_delete: null,

    };
  }
};

</script>

<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
