<!--

  View for shop orders components.

  Old legacy code, just copy pasted it into here. Need or refactor at some point.

-->
<template>
  <div class="container">


    <b-modal
      size="lg"
      :title="(selected_item && selected_item.shop_order_id) ? $t('SHOP_ORDER.EDITING') : $t('SHOP_ORDER.CREATING')"
      ref="edit-form"
      hide-footer
      >

      <!-- Edit form -->
      <MemberShopOrderEditor
        :member="member"
        :item="selected_item"
        @cancel="() => { this.$refs['edit-form'].hide(); }"
        @created="shop_editor_created"
        @updated="shop_editor_updated"
      />

    </b-modal>

    <MemberShopOrderTable
      :member="member"
      :items="items"

      @selected="select_item_clicked"
      @delete="delete_item_clicked"
    />
  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import MemberShopOrderTable from '@/view/components/member_editor_v2/sections/member_shop_order/MemberShopOrderTable.vue';
import MemberShopOrderEditor from '@/view/components/member_editor_v2/sections/member_shop_order/MemberShopOrderEditor.vue';


export default {

  name: 'MemberShopOrdersView',

  props: ['member','items','company'],
  emits: ['delete'],
  mixins: [ toasts ],

  components: {
    MemberShopOrderTable,
    MemberShopOrderEditor
  },

  watch: {

  },

  computed: {
    creating() {
      return !(this.selected_item && this.selected_item.member_company_id)
    },
    is_mobile() {
      return is_mobile();
    },


  },

  methods: {

    delete_item_clicked(item) {
      this.$emit('delete', item);
    },

    create_item_clicked() {
      this.selected_item = {};

      this.$nextTick(()=>{
        this.$refs['edit-form'].show();
      });
    },

    async select_item_clicked(item) {
      this.selected_item = item;

      this.$nextTick(()=>{
        this.$refs['edit-form'].show();
      });
    },


    shop_editor_created(shop_item) {},

    shop_editor_updated(shop_item) {}

  },

  mounted() {

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {
      selected_item: {}
    };
  }
};

</script>


<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
