<template>
  <span style="display: inline-block;">
    <div class="mytooltip" v-if="show_tooltip">
      <span class="mytooltip-text">{{ tooltip }}</span>
    </div>
    <a
      class="btn btn-icon btn-light btn-sm"
      @click.prevent="on_clicked"
      @mouseover="handle_mouse_over($event)"
      @mouseleave="handle_mouse_leave"
    >
      <span class="svg-icon svg-icon-md svg-icon-primary">
        <i v-if="icon" :class="icon" style="color: #3699FF"></i>
        <inline-svg v-if="svg" :src="svg"></inline-svg>
      </span>
    </a>
  </span>
</template>


<script>
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'MemlistTextArea',
  props: {
    icon: {
      type: String,
      default: null
    },
    svg: {
      type: String,
      default: null
    },
    tooltip: {
      type: String,
      default: ''
    },
  },
  
  mixins: [toasts],
  emits: ['click',],

  watch: {
    
  },

  computed: {
    
  },
  mounted() {
    

  },
  beforeDestroy() {
    // Clean up event listeners when the component is destroyed
    const elements = document.querySelectorAll('.hoverable');
    elements.forEach((element) => {
      element.removeEventListener('mouseover', this.handle_mouse_over);
      element.removeEventListener('mouseleave', this.handle_mouse_leave);
    });
  },
  methods: {
    on_clicked() {
      this.$emit('click');
    },
    handle_mouse_over(event) {
      if (this.is_mouse_over) {
        return;
      }

      this.is_mouse_over = true;
      event.target.style.backgroundColor = 'lightblue';

      this.show_tooltip = true;
    },
    handle_mouse_leave(event) {
      
      event.target.style.backgroundColor = '';

      this.show_tooltip = false;
      this.is_mouse_over = false;
    },
  },
  data() {
    return {
      is_mouse_over: false,
      tooltip_position: { left: 0, top: 0 },
      show_tooltip: false,
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_tooltip.scss";

.mytooltip {
  position: relative;
}

.mytooltip .mytooltip-text {
  position: absolute;
  left: 50%;
  top: -37px;
  width: 160px;
  text-align: center;
  margin-left: -80px;
  z-index: 9999;
  background: #ffffff;
  border: 1px solid #3699ff;
  padding: 5px;
  border-radius: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
