<!--

  Controller for Member company editor and table

  We keep the Api calls in this container so that we can construct
  unit tests of the MemberCompanyForm and MemberCompanyTable without
  introducing the complexity of the Api calls inside the components.

-->
<template>
  <div class="container">

    <ErrorPopup :error="error" />

    <Confirm
      :title="$t('MEMBERSHIP.DELETE')"
      :message="$t('MEMBERSHIP.CONFIRM_DELETE')"
      ref="confirmModal"
      @cancel="$refs['confirmModal'].hide()"
      @confirm="confirm_delete_member_company"
    />

    <b-modal
      :title="creating ? $t('MEMBERSHIP.CREATING') : $t('MEMBERSHIP.EDITING')"
      ref="edit-form"
      hide-footer
      >

      <!-- Edit form -->
      <MemberCompanyForm
        :member="member"
        :items="items"
        :item="selected_item"
        @cancel="() => { this.$refs['edit-form'].hide(); }"
        @created="member_company_created"
        @updated="member_company_updated"
      />


    </b-modal>

    <!-- Table -->
    <MemberCompanyTable
      :items="items"
      :view_only="false"
      :member="member"
      :company="company"
      @select="select_item_clicked"
      @delete="delete_item_clicked"
      @create="create_item_clicked"
    />

  </div>

</template>


<script>

import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import MemberCompanyForm from '@/view/components/member_editor_v2/sections/member_company/MemberCompanyForm.vue';
import MemberCompanyTable from '@/view/components/member_editor_v2/sections/member_company/MemberCompanyTable.vue';
import ErrorPopup from '@/view/components/ErrorPopup.vue';

export default {

  name: 'MemberCompaniesView',

  props: ['member','items','company'],
  emits: ['created', 'updated', 'deleted'],
  mixins: [ toasts ],

  components: {
    MemberCompanyForm,
    MemberCompanyTable,
    ErrorPopup,
  },

  watch: {

  },

  computed: {
    creating() {
      return !(this.selected_item && this.selected_item.member_company_id)
    },
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {

    async confirm_delete_member_company() {
      try {
        const res = await axios.delete(`/member_company/${this.item_to_delete.member_company_id}`);

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBERSHIP.DELETED'));

          this.$emit('deleted', this.item_to_delete.member_company_id);
        }
        else if (res.status === 403) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.PERMISSION_DENIED'));
        }
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBERSHIP.UNABLE_DELETE'));
      }

    },


    async delete_item_clicked(item) {
      if (this.items.length === 1) {
        this.error = 'ERR_CANT_REMOVE_ALL_MEMBERSHIPS';

        this.$nextTick(()=>{ this.error = null; });

        return;
      }

      if (item.invoice_id) {
        this.error = 'ERR_CANT_REMOVE_MEMBERSHIP_WITH_INVOICE';

        this.$nextTick(()=>{ this.error = null; });

        return;
      }

      this.item_to_delete = item;
      this.$refs['confirmModal'].show();
    },


    member_company_updated(item) {
      this.$emit('updated', item);

      this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('MEMBERSHIP.UPDATED'));

      this.$refs['edit-form'].hide();
    },

    member_company_created(item) {
      this.$emit('created', item);

      this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('MEMBERSHIP.CREATED'));

      this.$refs['edit-form'].hide();
    },


    create_item_clicked() {
      this.selected_item = {};

      this.$nextTick(()=>{
        this.$refs['edit-form'].show();
      });
    },

    async create_member_company(item) {
      try {
        const res = await axios.post(`/member_company/${this.member.member_id}`, item);

        if (res.status === 201) {
          this.create_item(res.data);
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('MEMBERSHIP.CREATED'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBERSHIP.UNABLE_CREATE'));
      }
    },

    async update_member_company() {
      try {
        const res = await axios.put(`/member_company/${this.item.member_company_id}`, item);

        if (res.status === 200) {
          this.$emit('updated', res.data);
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('MEMBERSHIP.UPDATED'));
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBERSHIP.UNABLE_UPDATE'));
      }
    },

    async select_item_clicked(item) {
      this.selected_item = item;

      this.$nextTick(()=>{
        this.$refs['edit-form'].show();
      });
    },

  },

  mounted() {


  },

  data() {
    return {

      error: null,

      selected_item: {},

      item_to_delete: null,

    };
  }
};

</script>


<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
