<!--

  Options

  Just dump a bunch of buttons

-->
<template>
  <div class="container">

    <Confirm
      :title="$t('MEMBER.RESET_PASSWORD')"
      :message="$t('MEMBER.RESET_PASSWORD_INFO')"
      ref="resetPass"
      :type="'success'"
      @confirm="onResetPass"
    />

    <Confirm
      title="Radera medlem"
      :message="
        `Vänligen bekräfta att du vill ta bort ${member.firstname} ${member.lastname}. Genom att bekräfta denna uppmaning kommer medlem helt raderas ur systemet.`
      "
      ref="deleteMember"
      :type="'danger'"
      @confirm="$emit('onDelete', member.member_id)"
    />

    <b-row>
      <b-col md="9">
        <div class="pt-2">
          {{$t('MEMBER.SEND_RESET_PASSWORD')}}
        </div>
      </b-col>
      <b-col md="3" class="text-right">
        <div>
          <b-button
            variant="primary"
            class="btn-memberEditForm"
            size="sm"
            @click="showConfirm('SEND_RESET_PASSWORD_EMAIL')"
            >{{$t('COMMON.SEND')}}</b-button
          >
        </div>
      </b-col>
    </b-row>

    <hr class="my-4" />
    <b-row>
      <b-col md="9">
        <div class="pt-2">
          {{$t('MEMBER.SEND_MEMBER_JOINED_EMAIL')}}
        </div>
      </b-col>
      <b-col md="3" class="text-right">
        <div>
          <b-button
            variant="primary"
            class="btn-memberEditForm"
            size="sm"
            @click="sendMemberJoinedEmail"
            >{{$t('COMMON.SEND')}}</b-button
          >
        </div>
      </b-col>
    </b-row>

    <hr class="my-4" />
    <b-row>
      <b-col md="9">
        <div class="pt-2">
          {{$t('MEMBER.LOGIN_AS_MEMBER')}}
        </div>
      </b-col>
      <b-col md="3" class="text-right">
        <div>
          <b-button variant="primary" class="btn-memberEditForm" size="sm" @click="loginAsMember"
            >{{$t('COMMON.LOGIN')}}</b-button
          >
        </div>
      </b-col>
    </b-row>

    <hr class="my-4" />
    <b-row class="mb-4">
      <b-col md="9">
        <div class="pt-2">
          {{$t('MEMBER.DOWNLOAD_GDPR')}}
        </div>
      </b-col>
      <b-col md="3" class="text-right">
        <div>
          <b-button variant="primary" class="btn-memberEditForm" size="sm" @click.prevent="onExport"
            >{{$t('COMMON.DOWNLOAD')}}</b-button
          >
        </div>
      </b-col>
    </b-row>

    <hr class="my-4" />

    <b-row>
      <b-col md="9">
        <div class="pt-2">
          {{$t('MEMBER.DELETE_FROM_SYSTEM')}}
        </div>
      </b-col>
      <b-col md="3" class="text-right">
        <div>
          <b-button
            variant="danger"
            class="btn-memberEditForm"
            size="sm"
            @click="showConfirm('DELETE_MEMBER')"
            >{{$t('COMMON.DELETE')}}</b-button
          >
        </div>
      </b-col>

    </b-row>

  </div>

</template>


<script>

import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { MEMBER_LOGIN } from '@/core/services/store/member.auth.module';


export default {

  name: 'MemberOptionsView',

  props: ['member','items','company','creating_member'],
  emits: ['onDelete'],
  mixins: [ toasts ],

  components: {

  },

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'currentUserId', 'companies', 'periods', 'profile', 'settings','isTHS'])

  },

  methods: {

    showConfirm(val) {
      switch (val) {
        case 'SEND_RESET_PASSWORD_EMAIL':
          this.$refs['resetPass'].show();
          break;
        case 'DELETE_MEMBER':

          this.$refs['deleteMember'].show();

          break;
      }
    },


    onExport() {
      axios
        .get(`/member/xlsx?member_id=${this.member.member_id}&company_id=${this.currentCompanyId}`)
        .then(res => {
          var filename = res.data.filepath.replace(/^.*[\\\/]/, '');

          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);

          this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.EXPORT_SUCCESS'));
        })
        .catch(err => console.error(err));
    },

    async loginAsMember() {

      const res = await axios.post(`/member/admin/login/${this.member.member_id}`);

      if (res.status === 200) {
        this.$store.dispatch(MEMBER_LOGIN, res.data);
        
        window.open('/profile', '_blank');

      }
      else {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
      }

    },

    resetRenew() {
      axios
        .post('/member_renew', {
          member_id: this.member.member_id,
          reset: true
        })
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.RENEW_RESET'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
        });
    },


    onResetPass() {
      const payload = {
        member_id: this.member.member_id,
        email: this.member.email,
        company_id: this.currentCompanyId
      };
      axios
        .post(`/member/forget`, payload)
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.RESET_PASSWORD_SENT'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
        });
    },

    sendMemberJoinedEmail() {
      axios
        .post(`/member/joined_email/${this.member.member_id}`, {})
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('MEMBER.EMAIL_SENT'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.AN_ERROR_OCCURED'));
        });
    },
  },

  mounted() {

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {

    };
  }
};

</script>

<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
