<template>
  <div>

    <Confirm
      :title="$t('COMMON.CONFIRM')"
      :message="$t('INVOICE.CONFIRM_DELETE')"
      ref="confirm-delete"
      type="success"
      @confirm="on_confirm_delete"
    />


    <MemberInvoiceSendEmailPopup
      ref="send-email-popup"
      :member="member"
      :invoice_id="invoice_id"
    />

    <ManualPaymentModal
      ref="manualPaymentModal"
      :invoice_id="invoice_id"
      :amount="amount"
      :amount_paid="amount_paid"
      @on_manual_payment="on_manual_payment"
    />

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
        @click.prevent="$emit('create')"
        ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('INVOICE.CREATE')}}</a
      >

    </div>

    <b-row>
      <b-col cols="12">
        <b-table
          class="table-striped"
          :fields="headers"
          :items="items"
          head-variant="light"
          id="invoice-table"
        >

        <template #cell(status)="row">
          <v-chip
            style="font-size: 10px; color: red; border-color: red;"
            v-if="row.item.status === 'DELETED'"
            color="red"
            outlined
          >
            {{ $t('INVOICE.STATUSES.DELETED') }}
          </v-chip>

          <v-chip
            style="font-size: 10px; color: green; border-color: green;"
            v-else-if="row.item.status === 'PAID'"
            color="red"
            outlined
          >
            {{ $t('INVOICE.STATUSES.' + row.item.status) }}
          </v-chip>

          <v-chip
            style="font-size: 10px;"
            v-else
            color="red"
            outlined
          >
            {{ $t('INVOICE.STATUSES.' + row.item.status) }}
          </v-chip>


        </template>

          <template #cell(empty)="data">
            <div class="text-right">
              <a
                class="btn btn-icon btn-light btn-sm mx-2"
                @click.prevent="on_mark_paid_invoice(data.item.invoice_id, data.item.amount, data.item.amount_paid)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/file-invoice-dollar-solid.svg"></inline-svg>
                </span>
              </a>
              <a
                class="btn btn-icon btn-light btn-sm mx-2"
                @click.prevent="send_email_invoice(data.item.invoice_id)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/email.svg"></inline-svg>
                </span>
              </a>
              <a
                class="btn btn-icon btn-light btn-sm mx-2"
                @click.prevent="download_invoice(data.item.invoice_id)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/download-solid.svg"></inline-svg>
                </span>
              </a>
              <a
                v-if="data.item.status !== 'PAID' && data.item.status !== 'DELETED'"
                class="btn btn-icon btn-light btn-sm mx-2"
                @click.prevent="delete_invoice_clicked(data.item.invoice_id)"
              >
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                </span>
              </a>
            </div>
          </template>
        </b-table>
        <p class="ml-4" v-if="!items || items.length === 0">{{$t('INVOICE.NONE_FOUND')}}</p>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';

import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import MemberInvoiceSendEmailPopup from '@/view/components/member_editor_v2/sections/member_invoice/MemberInvoiceSendEmailPopup.vue';
import ManualPaymentModal from '@/view/components/ManualPaymentModal.vue';

export default {
  name: 'MemberInvoiceTable',
  components: {
    ManualPaymentModal,
    MemberInvoiceSendEmailPopup,
  },
  mixins: [ toasts ],
  props: ['member', 'items'],
  emits: ['create', 'updated', 'deleted'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies'])
  },
  data() {
    return {
      template_sms_options: [],
      template_email_options: [],

      email_template_id: null,

      invoice_id: null,
      amount: null, // the total amount of the invoice
      amount_paid: null,
      headers: [
        {
          key: 'created_at',
          label: this.$t('COMMON.CREATED_AT'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7'
        },
        {
          key: 'amount',
          label: this.$t('COMMON.TOTAL'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7',
          formatter: (_, __, item) => {
            return `${item.amount} ${item.currency}`;
          },
        },
        {
          key: 'status',
          label: this.$t('INVOICE.STATUS'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7',

        },
        {
          key: 'ocr',
          label: this.$t('INVOICE.OCR'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7'
        },
        {
          key: 'empty',
          label: '',
          thClass: ''
        }
      ],
      data: null
    };
  },
  watch: {
    items() {

    },
  },
  mounted() {

  },
  methods: {

    async on_confirm_delete() {
      await this.delete_invoice(this.invoice_id);
    },

    delete_invoice_clicked(invoice_id) {
      this.invoice_id = invoice_id;
      this.$refs['confirm-delete'].show();
    },

    async delete_invoice(invoice_id) {
      try {
        const res = await axios.delete(`/invoice/${invoice_id}`);

        if (res.status === 200) {
          this.$emit('deleted', res.data);
          this.toastr('success', this.$t('COMMON.OK'), this.$t('INVOICE.DELETED'));
        }
      }
      catch (err) {
        console.error('delete_invoice error', err);
      }
    },

    async send_email_invoice(invoice_id) {
      try {

        this.invoice_id = invoice_id;

        this.$refs['send-email-popup'].show();
      }
      catch (err) {
        console.error('send_email_invoice error', err);
      }
    },

    getStatus(status) {

      switch (status) {
        case 'PAID': return this.$t('COMMON.PAID');
        case 'NOT_PAID': return this.$t('COMMON.NOT_PAID');
        case 'PARTIALLY_PAID': return this.$t('COMMON.PARTIALLY_PAID');
      }

      return 'Inget';
    },

    on_manual_payment(invoice_id, sum, mark_membership_paid) {
      this.$emit('updated', invoice_id, sum, mark_membership_paid);

      if (this.amount === null) {
        this.amount = 0;
      }

      this.amount = this.amount + sum;
    },

    on_mark_paid_invoice(invoice_id, amount, amount_paid) {
      this.$refs['manualPaymentModal'].show();
      this.invoice_id = invoice_id;
      this.amount = amount;
      this.amount_paid = amount_paid;
    },

    async download_invoice(invoice_id) {

      try {
        const res = await axios.get(`/invoice/file/${invoice_id}`);

        if (res.status === 200) {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
          return;
        }
      }
      catch (err) {
        console.error('download_invoice', err);
      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.FILES.UNABLE_DOWNLOAD'));

    },

  }
};
</script>
<style scoped>
:deep .table > tbody > tr > td {
  vertical-align: middle;
}
#app .container {
  background-color: #293039;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 100%;
}
#bar {
  flex: 1;
  background-color: #293039;
  color: white;
  padding: 10px;
  display: flex;
  max-height: 65px;
}
#bar h1 {
  flex: 1;
  font-size: 16px;
  text-align: left;
}
#bar button {
  min-width: 80px;
  cursor: pointer;
}
@media screen and (min-width: 500px) {
  .table-responsive {
      overflow: visible;
  }
}

</style>
