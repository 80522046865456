<template>
  <b-modal ref="modal" v-model="showSPARModal" hide-footer>
    <div class="form-create-member border border-secondary">
      <b-row>
        <b-col md="6">
          <b-form-group label="Personnr" label-for="member-personnr">
            <b-form-input id="member-personnr" v-model="local_personnr" :state="state_validate_personnr()" />
          </b-form-group>
        </b-col>
        <b-col md="6" v-if="sparWaiting">
          <b-button :disabled="!state_validate_personnr()" variant="outline-success" @click="doSpar" class="mt-8">
            {{ $t('MEMBER.DO_SPAR') }}
          </b-button>
        </b-col>
      </b-row>

      <p style="font-weight: bold; color: red;" v-if="spar.is_protected">
        {{ $t('MEMBER.SECRET') }}
      </p>

      <p style="font-weight: bold; color: red;" v-if="spar.is_deactivated">
        {{ $t('MEMBER.DEACTIVATED') }} : {{ spar.deactivate_at }} : {{ format_reason() }}
      </p>

      <p style="font-weight: bold; color: red;" v-if="spar.date_of_death">
        {{ $t('MEMBER.DECEASED') }} : {{ spar.date_of_death }}
      </p>

      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('MEMBER.FIRSTNAME')" label-for="member-firstname">
            <b-form-input id="member-firstname" v-model="spar.fn" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group :label="$t('MEMBER.LASTNAME')" label-for="member-lastname">
            <b-form-input id="member-lastname" v-model="spar.ln" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-6">
        <b-col md="12">
          <b-form-group :label="$t('MEMBER.ADDRESS')" label-for="member-street">
            <b-form-input id="member-street" v-model="spar.st" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="5">
          <b-form-group :label="$t('MEMBER.ZIPCODE')" label-for="member-postcode">
            <b-form-input id="member-postcode" v-model="spar.zip" />
          </b-form-group>
        </b-col>
        <b-col md="7">
          <b-form-group :label="$t('MEMBER.POSTAL')" label-for="member-postal">
            <b-form-input id="member-postal" v-model="spar.pa" />
          </b-form-group>
        </b-col>
      </b-row>


      <b-row v-if="showError">
        <b-col lg="12">
          <b-alert variant="danger" show>{{ errorText }}</b-alert>
        </b-col>
      </b-row>

      <b-row v-if="sparOK" class="mt-6">
        <b-col lg="6">
          <b-button variant="outline-success" @click="save">
            {{ $t('COMMON.SAVE') }}
          </b-button>
        </b-col>
        <b-col lg="6">
          <b-button variant="outline-primary" @click="cancel">
            {{ $t('COMMON.CANCEL') }}
          </b-button>
        </b-col>
      </b-row>

      <b-row v-if="showError" class="mt-6">
        <b-col lg="6">
          <b-button variant="outline-primary" @click="cancel">
            {{ $t('COMMON.CLOSE') }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>
<style lang="scss" scoped>
.form-create-member {
  max-width: 800px;
  margin: 0 auto;
  padding: 25px;
}
</style>
<script>
import axios from 'axios';

import validate_pers_number from '@/core/services/personnr';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'spar-lookup-member',
  components: {

  },

  mixins: [ toasts ],

  data() {
    return {

      local_personnr: null,


      showError: false,
      errorText: this.$t('COMMON.AN_ERROR_OCCURED'),
      showSPARModal: false,
      sparWaiting: true,
      sparOK: false,
      spar: {
        pa: '',
        fn: '',
        ln: '',
        st: '',
        zip: '',
        rc: '',
        cc: '',
        co: '',
      },
    };
  },
  props: {
    member_id: { type: [String, Number], default: null },
    personnr: { type: [String], default: null },
  },
  emits: ['save', 'cancel'],
  computed: {

  },
  watch: {

    member_id: {
      deep: true,
      handler(val) {
        this.reset();
      }
    },

    personnr: {
      deep: true,
      handler(val) {
        this.local_personnr = this.personnr;
      }
    },

  },

  async mounted() {
    this.reset();

    if (this.personnr) {
      this.local_personnr = this.personnr;
    }
  },
  methods: {
    format_reason() {

      if (this.spar.deactivate_reason) {
        return this.$t('MEMBER.SPAR_REASONS.'+this.spar.deactivate_reason)
      };
      
      return this.$t('COMMON.UNKNOWN');
    },
    state_validate_personnr() {
      const valid = validate_pers_number(this.local_personnr);

      return valid.valid;
    },
    show() {
      this.showSPARModal = true;

      if (this.member_id) {
        this.load_member();
      }

      this.reset();
    },
    close() {
      this.showSPARModal = false;
    },
    doSpar() {

      if (this.state_validate_personnr()) {
        this.do_SPAR_lookup_personnr(this.local_personnr);
      }
      else {
        this.do_SPAR_lookup_member_id(this.member_id);
      }
    },
    cancel() {
      this.$refs['modal'].hide();
      this.$emit('cancel');
    },
    save() {
      const valid = validate_pers_number(this.local_personnr);
      this.$emit('save', this.spar, this.local_personnr, valid.formatted, valid.sex);

      this.$refs['modal'].hide();
    },
    async load_member() {

      try {
        const res = await axios.get(`/member/${this.member_id}`);

        if (res.status === 200) {
          let fmt_personnr = res.data.member.fmt_personnr;
          let in_personnr = res.data.member.in_personnr;

          const fmt_valid = validate_pers_number(fmt_personnr);
          const in_valid = validate_pers_number(in_personnr);

          if (fmt_valid.valid) {
            this.local_personnr = fmt_valid.formatted;
          }
          else if (in_valid.valid) {
            this.local_personnr = in_valid.formatted;
          }
          else if (in_personnr && (in_personnr + '').length > 8) {
            this.local_personnr = in_personnr;
          }
          else if (fmt_personr && (fmt_personnr + '').length > 8) {
            this.local_personnr = fmt_personnr;
          }

        }

      }
      catch (err) {
        console.error('load_member error', err);
      }
    },

    reset() {
      this.spar = {
        pa: '',
        fn: '',
        ln: '',
        st: '',
        zip: '',
        rc: '',
        cc: '',
        co: '',
        date_of_death: null,
        deactivate_at: null,
        deactivate_reason: null,
        is_protected: false,
      };
      this.sparOK = false;
      this.showError = false;
      this.sparWaiting = true;
    },

    async do_SPAR_lookup_personnr(personnr) {
      this.sparWaiting = false;

      try {
        const res = await axios.post(`/member/spar/personnr`, { personnr })

        if (res.status === 200) {
          this.spar = res.data.spar;
          this.sparOK = true;
        }
        else if (res.data.reason === 'SPAR disabled') {
          this.showError = true;
          this.errorText = this.$t('MEMBER.SPAR_DISABLED');
        }
        else {
          this.showError = true;
          this.errorText = this.$t('COMMON.AN_ERROR_OCCURED');
        }
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_DO_SPAR'));
        this.showError = true;
      }
    },

    async do_SPAR_lookup_member_id(member_id) {
      this.sparWaiting = false;

      try {
        const res = await axios.get(`/member/spar/memberid/${member_id}`);


        if (res.data.reason === 'SPAR disabled') {
          this.showError = true;
          this.errorText = this.$t('MEMBER.SPAR_DISABLED');
        }
        else if (res.status === 200) {
          this.spar = res.data;
          this.sparOK = true;
        }
        else {
          this.showError = true;
          this.errorText = this.$t('COMMON.AN_ERROR_OCCURED');
        }

      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER.UNABLE_DO_SPAR'));
        this.showError = true;
      }
    },
  }
};
</script>
<style scoped></style>

