<template>
  <div>

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
      <a href="#" class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2" @click.prevent="$emit('create')"><i
          class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('PARENT.CREATE') }}</a>

    </div>

    <!-- Table -->
    <div class="table-container">
      <b-table 
        id="parents-table" 
        class="table-striped" 
        :fields="fields" 
        :items="items" 
        head-variant="light"
        ref="parents-table">
        <template #cell(action)="data">
          <div class="text-right" v-if="!view_only">
            <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="edit_row_clicked(data.item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="delete_row_clicked(data.item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>

      <p class="ml-4" v-if="items.length === 0">{{ $t('PARENT.NONE_FOUND') }}</p>

    </div>

  </div>
</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';

export default {

  name: 'MemberParentTable',

  props: ['member', 'items', 'company'],
  emits: ['select', 'delete', 'create'],

  mixins: [toasts],

  components: {

  },

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {

    async edit_row_clicked(item) {

      this.$nextTick(() => {
        this.$emit('select', item);
      });
    },

    async delete_row_clicked(item) {
      this.$emit('delete', item);
    },

  },

  mounted() {

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {

      fields: [
        {
          key: 'firstname',
          label: this.$t('MEMBER.FIRSTNAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'lastname',
          label: this.$t('MEMBER.LASTNAME'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'email',
          label: this.$t('MEMBER.EMAIL'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'phone',
          label: this.$t('MEMBER.PHONE'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

        {
          key: 'action',
          label: '',
          tdClass: 'td-actions',
          thClass: 'td-actions',
          sortable: false
        }
      ],

      show_form: true,

    };
  }
};

</script>
<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
