<template>
  <div>

    <!-- Table -->
    <div class="table-container">
      <b-table

        id="member-table"
        class="table-striped"
        :fields="fields"
        :items="local_items"
        head-variant="light"
        ref="member-table"
        :tbody-tr-class="rowClass"
      >
      <template #cell(action)="data">
        <div class="text-right" v-if="!view_only">
          <a v-if="!data.item.is_paid && !data.item.invoice_id && !data.item.added && data.item.shop_item !== null" href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="add_membership_to_invoice(data.item.member_company_id)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/circle-plus-solid.svg"></inline-svg>
            </span>
          </a>

          <a
            v-if="data.item.added"
            class="btn btn-icon btn-light btn-sm"
            @click.prevent="delete_row_clicked(data.item.member_company_id)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
        </div>
      </template>
      </b-table>

      <p class="ml-4" style="color: red;" v-if="!has_invoicable_items">{{$t('INVOICE.NO_UNPAID_MEMBERSHIPS')}}</p>

    </div>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';

export default {

  name: 'MemberCompanyTable',

  props: ['member','company','items','creating_member','view_only'],
  emits: ['select', 'selected', 'delete'],

  mixins: [ toasts ],

  components: {

  },

  watch: {

    // when examples has changed in the parent
    // we need to update the local items shown in the table
    items: {
      deep: true,
      handler(val) {
        this.local_items = [ ...this.items ]
        this.local_items = this.local_items.filter(item => !item.is_paid && (item.invoice_id === null || item.invoice_id === undefined));
      }
    },

  },

  computed: {
    has_invoicable_items() {
      const invoiceable_items = this.items.filter(item => !item.is_paid && (item.invoice_id === null || item.invoice_id === undefined));

      return invoiceable_items.length > 0;
    },
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {

    rowClass(item, type) {
      if (item && type === 'row') {
        if (item.added) {
          return 'bg-success';
        }
      }
      return null;
    },

    /// set the "added" on the invoice item to true
    add_membership_to_invoice(member_company_id) {
      const item = this.items.find(item => item.member_company_id === member_company_id);

      item.added = true;

      this.items = [...this.items];

      this.$emit('selected', this.items);
    },

    async edit_row_clicked(item) {

      this.$nextTick(()=>{
        this.$emit('select', item);
      });
    },

    async delete_row_clicked(member_company_id) {
      const item = this.items.find(item => item.member_company_id === member_company_id);

      item.added = false;

      this.items = [...this.items];

      this.$emit('selected', this.items);
    },

  },

  mounted() {

    if (this.items) {
      this.local_items = [ ...this.items ];
      this.local_items = this.local_items.filter(item => !item.is_paid && (item.invoice_id === null || item.invoice_id === undefined));
    }

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {

      local_items: [],

      fields: [
        {
          key: 'company.name',
          label: this.$t('COMPANY.NAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'vtdt',
          label: this.$t('MEMBERSHIP.VTDT'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },
        {
          key: 'period',
          label: this.$t('MEMBERSHIP.PERIOD'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {
            return item.period.name;
          }
        },
        {
          key: 'is_paid',
          label: this.$t('MEMBERSHIP.IS_PAID'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {
            return item.is_paid ? this.$t('COMMON.YES') : this.$t('COMMON.NO');
          }
        },
        {
          key: 'shop_item',
          label: this.$t('INVOICE.SHOP_ITEM'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {
            return item.shop_item ? item.shop_item.name : this.$t('INVOICE.NO_SHOP_ITEM');
          }
        },
        {
          key: 'action',
          label: '',
          tdClass: 'td-actions',
          thClass: 'td-actions',
          sortable: false
        }
      ],

      show_form: true,

    };
  }
};

</script>


<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>

