<!--

  Legacy solution for renewals

-->
<template>
  <div class="container">

    <b-modal
      :title="creating ? $t('MEMBER_RENEW.CREATING') : $t('MEMBER_RENEW.EDITING')"
      ref="edit-form"
      hide-footer
      >

      <!-- Edit form -->
      <MemberRenewEditor
        :member="member"
        :item="selected_item"
        :creating="creating"
        @cancel="() => { this.$refs['edit-form'].hide(); }"
        @created="renew_created"
        @updated="renew_updated"
      />


    </b-modal>

    <MemberRenewTable
      ref="member-renew-table"
      :member="member"
      @select="select_item_clicked"
      @delete="delete_item_clicked"
      @create="create_item_clicked"
    />
  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';

import MemberRenewTable from '@/view/components/member_editor_v2/sections/member_renew/MemberRenewTable.vue';
import MemberRenewEditor from '@/view/components/member_editor_v2/sections/member_renew/MemberRenewEditor.vue';


export default {

  name: 'MemberRenewalsView',

  props: ['member','company','creating_member'],
  emits: ['delete'],
  mixins: [ toasts ],

  components: {

    MemberRenewTable,
    MemberRenewEditor
  },

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId']),

  },

  methods: {

    renew_created() {
      this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('MEMBER_RENEW.CREATED'));
      this.$refs['member-renew-table'].refresh();
      this.$refs['edit-form'].hide();
    },

    renew_updated() {
      this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('MEMBER_RENEW.UPDATED'));
    },

    create_item_clicked() {
      this.selected_item = {};
      this.creating = true;

      this.$nextTick(()=>{
        this.$refs['edit-form'].show();
      });
    },

    delete_item_clicked(id) {
      this.$emit('delete', id);
    },

    select_item_clicked(item) {
      this.selected_item = item;
      this.creating = false;

      this.$refs['edit-form'].show();
    },
  },

  mounted() {


  },

  data() {
    return {

      creating: true,

      selected_item: {},

      fields: [
        {
          key: 'name',
          label: this.$t('FILE.FILENAME'),
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7',
        },
        {
          key: 'created_at',
          label: this.$t('FILE.CREATED_AT'),
          sortable: true,
          thClass: 'w-150px',
        },
        {
          key: 'file_id',
          label: '',
          thClass: 'w-150px text-right pr-22',
        },
      ],

    };
  }
};

</script>

<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
