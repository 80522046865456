<template>
  <div>

    <div v-if="selected_file_row" class="file-row-editor">
      <b-form ref="edit-form" class="mt-8">

        <b-row>
          <b-col md="6" sm="12">
            <b-form-group id="group-id" label="ID" label-for="input-id">
              <b-form-input
                id="input-id"
                v-model="selected_file_row.id"
                type="text"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" sm="12">
            <b-form-group id="group-ladok_file_id" :label="$t('EDUCATION.LADOK_FILE_ID')" label-for="input-ladok_file_id">
              <b-form-input
                id="input-ladok_file_id"
                v-model="selected_file_row.ladok_file_id"
                type="text"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" sm="12">
            <b-form-group id="group-student_id" :label="$t('EDUCATION.STUDENT_ID')" label-for="input-student_id">
              <b-form-input
                id="input-student_id"
                v-model="selected_file_row.student_id"
                type="text"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" sm="12">
            <b-form-group id="group-kurskod" :label="$t('EDUCATION.COURSE_CODE')" label-for="input-kurskod">
              <b-form-input
                id="input-kurskod"
                v-model="selected_file_row.kurskod"
                type="text"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" sm="12">
            <b-form-group id="group-programkod" :label="$t('EDUCATION.PROGRAM_CODE')" label-for="input-programkod">
              <b-form-input
                id="input-programkod"
                v-model="selected_file_row.programkod"
                type="text"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" sm="12">
            <b-form-group id="group-personnr" :label="$t('MEMBER.PERSONNR')" label-for="input-personnr">
              <b-form-input
                id="input-personnr"
                v-model="selected_file_row.personnr"
                type="text"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" sm="12">
            <b-form-group id="group-fakultet" :label="$t('EDUCATION.FACULTY')" label-for="input-fakultet">
              <b-form-input
                id="input-fakultet"
                v-model="selected_file_row.fakultet"
                type="text"
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
         
        </b-row>
        
      </b-form>
    </div>
    
    <b-table
      id="member-ladok-rows-table"
      :items="ladok_file_rows"
      :fields="headers"
      head-variant="light"
      ref="member-ladok-rows-table"
      class="mt-16 table-striped"
      @filtered="onFiltered"
    >
      <template #cell(empty)="row">
        <div class="d-flex justify-content-end">
          <a class="btn btn-icon btn-light btn-sm mx-2" @click.prevent="select_ladok_file_row_clicked(row.item)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            </span>
          </a>
        </div>
      </template>
    </b-table>

    <p v-if="ladok_file_rows.length === 0">
      {{ $t('EDUCATION.NO_LADOK_ROWS') }}
    </p>

    <b-row v-else class="mt-8">
      <b-col><span>{{ $t('PAGES.EDUCATIONS.NUM_ROWS') }} {{ ladok_file_rows.length }}</span></b-col>
    </b-row>
    
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import EducationTable from '@/view/pages/ml/education/EducationTable.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'MemberEducationsTable',
  components: {
    EducationTable
  },
  mixins: [ toasts ],
  props: ['member'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods'])
  },
  data() {
    return {
      ladok_file_rows: [],
      selected_file_row: null,
      headers: [
        {
          key: 'created_at',
          label: this.$t('EDUCATION.CREATED_AT'),
        },
        {
          key: 'student_id',
          label: this.$t('EDUCATION.STUDENT_ID')
        },
        {
          key: 'kurskod',
          label: this.$t('EDUCATION.COURSE_CODE'),
        },
        {
          key: 'programkod',
          label: this.$t('EDUCATION.PROGRAM_CODE'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7'
        },
        {
          key: 'fakultet',
          label: this.$t('EDUCATION.FACULTY'),
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7'
        },
        {
          key: 'empty',
          label: '',
          thClass: ''
        }
      ],
    };
  },
  watch: {
    member(new_value) {

      console.error('ladok mutated', new_value);

      if (new_value.member_id) { // only refresh when member id is valid
        this.load_ladok_file_rows();
      }
    },
  },
  mounted() {

    if (this.member) {
      console.error('ladok mounted', this.member);
      this.load_ladok_file_rows();
    }
    
  },
  methods: {

    async load_ladok_file_rows() {
      try {
        const res = await axios.get(`/education/member/ladok/${this.member.member_id}`);

        if (res.status === 200) {
          this.ladok_file_rows = res.data;
        }

      }
      catch (err) {

      }
    },

    select_ladok_file_row_clicked(ladok_row) {
      this.selected_file_row = ladok_row;
    },
    
    getTypeStr(type) {
      switch (type) {
        case 'NONE': return 'Ej satt';
        case 'PROGRAM': return 'Program';
        case 'COURSE': return 'Kurs';
        case 'FREE_COURSE': return 'Fri kurs';
      }

      return 'Okänt';
    },

    
    
    onFiltered(filteredItems) {}, 
    
    getOptions() {

      return {
        text: null
      };
    },

    refresh() {
      this.$refs['memberEducationsTable'].refresh();
    },


    getTypeStr(type) {
      switch (type) {
        case 'NONE': return 'Ej satt';
        case 'PROGRAM': return 'Program';
        case 'COURSE': return 'Kurs';
        case 'FREE_COURSE': return 'Fri kurs';
      }

      return 'Okänt';
    },
  }
};
</script>
<style scoped>

</style>
